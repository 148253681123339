import { useTranslate } from 'react-admin';

import React from 'react'
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import styles from './CopyToClipboard.module.css'

const CopyToClipboard = ({value}) => {
    const translate = useTranslate();
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <Paper sx={{height: '100px'}} className={styles.copyBox}>
            <Box sx={{p: '20px'}}>
                {value}
            </Box>
            <Tooltip title={translate('telemedic.actions.copyToClipboard')}>
                <Button onClick={handleCopy} sx={{minWidth: '30px'}}>
                    <ContentCopyIcon sx={{height: '20px', color: '#216C17'}}/>
                </Button>
            </Tooltip>
        </Paper>
    );
}

export default CopyToClipboard;