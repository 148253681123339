import rutoken from 'rutoken';
import './cadesplugin_api';

class RutokenService {

  constructor(plugin) {
    this.pluginPromise = rutoken.ready.then(() => {
      this._log('initialization...');
      if (window.chrome) {
        return rutoken.isExtensionInstalled();
      } else {
        return Promise.resolve(true);
      }
    }).then((result) => {
      if (result) {
        return rutoken.isPluginInstalled();
      } else {
        return Promise.reject(new Error('Не установлено расширение Рутокен!'));
      }
    }).then((result) => {
      if (result) {
        return rutoken.loadPlugin();
      } else {
        return Promise.reject(new Error('Плагин Рутокен не установлен!'));
      }
    }).then((plugin) => {
      this._log('initialization: ok');
      return plugin;
    });
    this.lastDeviceId = null;
    this.lastCertificateId = null;
  }

  async requestCertificate() {
    try {
      await window.cadesplugin;
      
      return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function* (args) {
          try {
            const oStore = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.Store');
            yield oStore.Open(window.cadesplugin.CAPICOM_CURRENT_USER_STORE, window.cadesplugin.CAPICOM_MY_STORE,
              window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

            const certificates = yield oStore.Certificates;
            const certsCount = yield certificates.Count;
            
            if (certsCount === 0) {
              throw new Error('Сертификаты не найдены');
            }

            // Собираем информацию о сертификатах
            const certsArray = [];
            for(let i = 1; i <= certsCount; i++) {
              const cert = yield certificates.Item(i);
              const subjectName = yield cert.SubjectName;
              const validFrom = yield cert.ValidFromDate;
              const validTo = yield cert.ValidToDate;
              
              certsArray.push({
                cert: cert,
                subject: subjectName,
                validFrom: validFrom,
                validTo: validTo
              });
            }

            // Показываем диалог выбора
            const selectedCert = yield new Promise((resolve) => {
              // Создаем затемненный фон (оверлей)
              const overlay = document.createElement('div');
              overlay.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.5);z-index:9998;';
              
              const dialog = document.createElement('div');
              dialog.style.cssText = 'position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);background:white;padding:20px;border:1px solid black;z-index:9999;max-height:80vh;overflow:auto;box-shadow:0 4px 8px rgba(0,0,0,0.3);border-radius:4px;';
              dialog.innerHTML = `
                <h3>Выберите сертификат для подписи</h3>
                <div id="certList">
                  ${certsArray.map((cert, index) => `
                    <div style="margin:10px 0;padding:10px;border:1px solid #ccc;cursor:pointer" 
                         onclick="window.selectCertificate(${index})">
                      <div><strong>Субъект:</strong> ${cert.subject}</div>
                      <div><strong>Действителен с:</strong> ${cert.validFrom}</div>
                      <div><strong>Действителен по:</strong> ${cert.validTo}</div>
                    </div>
                  `).join('')}
                </div>
              `;

              document.body.appendChild(overlay);
              document.body.appendChild(dialog);
              window.selectCertificate = (index) => {
                document.body.removeChild(dialog);
                document.body.removeChild(overlay);
                delete window.selectCertificate;
                resolve(certsArray[index].cert);
              };
            });

            yield oStore.Close();
            args[0](selectedCert);
            
          } catch (e) {
            const err = window.cadesplugin.getLastError(e);
            console.log('Failed to get certificate:', err);
            args[1](err);
          }
        }, resolve, reject);
      });
    } catch (error) {
      console.log('Failed to initialize plugin:', error);
      throw error;
    }
  }

  signCreate(dataToSign) {
    return new Promise(async (resolve, reject) => {
      try {
        // Запрашиваем сертификат только если он еще не выбран
        if (!this.cadescert) {
          this.cadescert = await this.requestCertificate();
        }

        window.cadesplugin.async_spawn(function* (args) {
          try {
            const oSigner = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
            yield oSigner.propset_Certificate(args[3]); // используем сохраненный сертификат
            yield oSigner.propset_CheckCertificate(true);

            const oSignedData = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
            yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(args[2]);

            const sSignedMessage = yield oSignedData.SignCades(oSigner, window.cadesplugin.CADESCOM_CADES_BES, true);
            args[0](sSignedMessage);
            
          } catch (e) {
            const err = window.cadesplugin.getLastError(e);
            console.log('Failed to create signature:', err);
            args[1](err);
          }
        }, resolve, reject, dataToSign, this.cadescert);
      } catch (error) {
        console.log('Failed to initialize plugin:', error);
        reject(error);
      }
    });
  }

  verify(data, sSignedMessage) {
    if (!window.cadesplugin) {
      return
    }
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(function* (args)  {
            var err;
            const oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
            yield oSignedData.propset_Content(data);
            try {
                yield oSignedData.VerifyCades(sSignedMessage, window.cadesplugin.CADESCOM_CADES_BES, true);
            }
            catch (e) {
                err = window.cadesplugin.getLastError(e);
                alert("Failed to verify signature. Error: " + err);
                return args[1](err);
            }
            return args[0]();
        }, resolve, reject);
    });
  }

  checkDeviceAvailability() {
    return this.selectDevice().then(() => {});
  }

  selectDevice() {
    this._log('selectDevice...');
    return this.pluginPromise.then((plugin) => {
      return plugin.enumerateDevices().then((devices) => {
        if (devices.length > 0) {
          this._log('selectDevice: ok');
          this.lastDeviceId = devices[0];
          return devices[0];
        }
        return Promise.reject(new Error('ЭЦП токен не вставлен или не подходящий!'));
      });
    });
  }

  login(deviceId) {
    this._log('login...');
    return this.pluginPromise.then((plugin) => {
      return plugin.getDeviceInfo(deviceId, plugin.TOKEN_INFO_IS_LOGGED_IN).then((loggedIn) => {
        if (!loggedIn) {
          return plugin.login(deviceId, '12345678');
        }
      }).then(() => {
        this._log('login: ok');
      });
    });
  }

  selectCertificate(deviceId) {
    this._log('selectCertificate...');
    return this.pluginPromise.then((plugin) => {
      var categories = [
        plugin.CERT_CATEGORY_USER, plugin.CERT_CATEGORY_CA,
        plugin.CERT_CATEGORY_OTHER, plugin.CERT_CATEGORY_UNSPEC
      ];
      return this._getActiveCertificateIdInCategories(plugin, deviceId, categories).then((certificate) => {
        if (certificate) {
          this._log('selectCertificate: ok');
          this.lastCertificateId = certificate.id;
          return certificate;
        }
        return Promise.reject(new Error('Действующий сертификат не найден!'));
      });
    });
  }

  getCertificatePublicKey(deviceId, certificateId) {
    this._log('getCertificatePublicKey...');
    return this.pluginPromise.then((plugin) => {
      return new Promise((resolve, reject) => {
        var promise = plugin.getKeyByCertificate(deviceId, certificateId);
        if (typeof(promise.catch) == 'function') {
          promise.then((keyId) => {
            return plugin.getPublicKeyValue(deviceId, keyId, {});
          }).then((keyValue) => {
            this._log('getCertificatePublicKey: ok');
            resolve(keyValue);
          }).catch((e) => {
            this._log('getCertificatePublicKey: fail');
            resolve(certificateId);
          });
        }
        else {
          resolve(certificateId);
        }
      });
    });
  }

  getCertificate(deviceId, certificateId) {
    this._log('getCertificate...');
    return this.pluginPromise.then((plugin) => {
      return plugin.getCertificate(deviceId, certificateId).then((pem) => {
        this._log('getCertificate: ok');
        return pem;
      });
    });
  }

  parseCertificate(deviceId, certificateId) {
    this._log('parseCertificate...');
    return this.pluginPromise.then((plugin) => {
      return plugin.parseCertificate(deviceId, certificateId).then((certificateData) => {
        this._log('parseCertificate: ok');
        return certificateData;
      });
    });
  }

  sign(deviceId, certificateId, data) {
    this._log('sign...');
    return this.signCreate(data).then(
        (signedData) => (
          this.verify(data, signedData).then(
            () => {
              this._log("Signature verified");
              return signedData
            },
            (err) => {
              throw Error(err)
            }
          )
        ),
        (err) => {
          throw new Error(err)
        }
    );
  }

  //shortcuts
  getDeviceAndCertificateId() {
    var deviceId = null;
    return this.selectDevice().then((selectedDeviceId) => {
      deviceId = selectedDeviceId;
      return this.login(deviceId);
    }).then(() => {
      return this.selectCertificate(deviceId);
    }).then((selectedCertificate) => {
      return {
        deviceId: deviceId,
        certificateId: selectedCertificate.id
      };
    });
  }

  withCertificateIdAndDevice(promiseFactory) {
    if (this.lastDeviceId !== null && this.lastCertificateId !== null) {
      return promiseFactory({deviceId: this.lastDeviceId, certificateId: this.lastCertificateId})
        .catch((e) => {
            console.log(e, typeof(e));
            // 3 - DEVICE_NOT_FOUND, 22 - CERTIFICATE_NOT_FOUND
            if (e === 3 || e === 22) {
                console.log('Re-request deviceId and certificateId');
                return this._getDeviceCertificateIdAndRun(promiseFactory);
            }
            if (e.errorDescription) {
              return Promise.reject({
                errorText: e.errorDescription,
              });
            }
            return Promise.reject(e);
        });
    } else {
      return this._getDeviceCertificateIdAndRun(promiseFactory)
    }
  }

  _getDeviceCertificateIdAndRun(promiseFactory) {
    return this.getDeviceAndCertificateId().then((result) => {
      return promiseFactory(result);
    });
  }

  _findActiveCertificateIdInList(plugin, deviceId, certificates) {
    if (certificates.length > 0) {
      var certificateId = certificates[certificates.length - 1];
      return plugin.parseCertificate(deviceId, certificateId).then((certificate) => {
        var currentDate = (new Date()).toISOString();
        if (currentDate >= certificate.validNotBefore && currentDate <= certificate.validNotAfter) {
          return Promise.resolve({
            id: certificateId,
            data: certificate,
          });
        }
        return this._findActiveCertificateIdInList(plugin, deviceId, certificates.slice(0, -1));
      });
    }
    return Promise.resolve(null);
  }

  _getActiveCertificateIdInCategories(plugin, deviceId, categories) {
    if (categories.length > 0) {
      return plugin.enumerateCertificates(deviceId, categories[0]).then((certificates) => {
        return this._findActiveCertificateIdInList(plugin, deviceId, certificates);
      }).then((certificate) => {
        if (certificate != null) {
          return Promise.resolve(certificate);
        }
        return this._getActiveCertificateIdInCategories(plugin, deviceId, categories.slice(1));
      });
    }
    return Promise.resolve(null);
  }

  _log(s) {
    console.log('rutoken wrapper:', s, Date());
  }
}

var instance = null;
export const getRutokenServiceInstance = () => {
  if (instance == null) {
    instance = new RutokenService();
  }
  return instance;
}

export default RutokenService;
