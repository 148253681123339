import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useInput, useTranslate} from 'react-admin';

import {union} from 'lodash';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import DropdownButton from '../DropdownButton';
import FormHelperTextSpan from '../FormHelperTextSpan';
import LargeCheckList from '../LargeCheckList';
import styles from './LargeCheckListInput.module.css';

const LargeCheckListInput = (props) => {
  const {
    choices,
    choiceLabelField,
    choiceMenu,
    choiceValueField,
    className,
    helperText,
    label,
    valueType
  } = props;
  const {
    meta: {touched, error},
    input,
    isRequired
  } = useInput(props);
  const translate = useTranslate();
  const labelText = label ? translate(label) : '';
  const createCallback = (filter) => {
    return () => {
      input.onChange(filter(choices).map(choice => choice[choiceValueField]));
    }
  }
  const dropdownButtonChoices = choiceMenu
    ? choiceMenu.map((menuItem) => ({
        label: menuItem.label,
        onSelect: createCallback(menuItem.filter)
      }))
    : null;
  return (
    <FormControl
      className={className}
      error={!!(touched && error)}
    >
      <div className={styles.control}>
        <FormLabel required={isRequired}>
          {labelText}
        </FormLabel>
        <EditorDialog {...input} choices={choices} choiceValueField={choiceValueField}/>
        <LargeCheckList
          required={isRequired}
          choices={choices}
          choiceLabelField={choiceLabelField}
          choiceValueField={choiceValueField}
          valueType={valueType}
          {...input}/>
          {choiceMenu &&
            <DropdownButton
              color="primary"
              id={input.name}
              label="telemedic.actions.select"
              choices={dropdownButtonChoices}
              fullWidth/>
          }
      </div>
      <FormHelperText error={!!(touched && error)}>
        <FormHelperTextSpan
          error={error}
          helperText={helperText}
          touched={touched}
          translate={translate}/>
      </FormHelperText>
    </FormControl>
  );
}

const EditorDialog = ({value, onChange, choices, choiceValueField}) => {
  const [open, setOpen] = useState(false);
  const [editorValue, setEditorValue] = useState('');

  const inputRef = useRef();

  const handleClickOpen = useCallback(() => {
    setOpen(true);
    setEditorValue(value ? value.join(',') : '');
  }, [setOpen, value]);

  const handleEditorValueChange = useCallback((event) => {
    setEditorValue(event.target.value);
  }, [setEditorValue]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleFill = useCallback(() => {
    const allValues = choices.map(choice => choice[choiceValueField]);
    setEditorValue(allValues.join(','));
    onChange(allValues);
    setOpen(false);
  }, [setOpen, choices, choiceValueField, onChange]);

  const handleCopy = useCallback(() => {
    inputRef.current.select();
    document.execCommand('copy');
    setOpen(false);
  }, [setOpen]);

  const handleAdd = useCallback(() => {
    const newValues = editorValue.split(',').map(v => parseInt(v));
    onChange(union(value, newValues));
    setOpen(false);
  }, [editorValue, onChange, setOpen, value]);

  const handleReplace = useCallback(() => {
    const newValues = editorValue.split(',').map(v => parseInt(v));
    onChange(newValues);
    setOpen(false);
  }, [editorValue, onChange, setOpen]);

  const translate = useTranslate();
  return (
    <React.Fragment>
      <IconButton className={styles.editButton} onClick={handleClickOpen} size="small">
        <CreateIcon fontSize="small"/>
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="editor-dialog-title">
        <DialogTitle id="editor-dialog-title">
          {translate('telemedic.largeCheckList.valueEditor')}
          <IconButton aria-label="close" className={styles.closeDialog} onClick={handleClose} size="small">
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="value"
            label={translate('telemedic.largeCheckList.value')}
            onChange={handleEditorValueChange}
            inputRef={inputRef}
            value={editorValue}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFill} color="primary">
            {translate('telemedic.actions.fill')}
          </Button>
          <Button onClick={handleCopy} color="primary">
            {translate('telemedic.actions.copy')}
          </Button>
          <Button onClick={handleAdd} color="primary">
            {translate('telemedic.actions.add')}
          </Button>
          <Button onClick={handleReplace} color="primary">
            {translate('telemedic.actions.replace')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

LargeCheckListInput.propTypes = {
  choices: PropTypes.array,
  choiceLabelField: PropTypes.string,
  choiceMenu: PropTypes.array,
  choiceValueField: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  label: PropTypes.string,
  valueType: PropTypes.string
};

export default LargeCheckListInput;
